@font-face {
    font-family: 'ResamitzBold';
    src: local('ResamitzBold'), url(./fonts/ResamitzBold_0048.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

.splashWrapper {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: fixed;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    text-align: center;
    text-transform: uppercase;
    font-family: 'ResamitzBold';
    
    .border {
        // min-width: calc(100vw-50px);
        // min-height: calc(100vh-50px);
        height: 95vh;
        width: calc(100vw - 5vh);
        position: absolute;
        border: #5D5C5C;
        border-width: 1px;
        border-style: solid;
        z-index: -1;
    }

    .container{
        max-width: 67vw;
        display: flex;
        flex-direction: column;
        align-items: center;

        #logo {
            width: 576px;
            margin: 0 0 25px 0;
        }
    
        h1 {
            margin: 0 0 10vh 0;
            font-size: 25px;
            font-weight: normal;
        }
    
        a{
            font-size: 25px;
            color: #000000;
            text-decoration: none;
            margin: 0 0 12px 0;
        }
    
        a:hover{
            color: #D6D6D6;
            // text-decoration: underline;
        }
    
            .socialIcons {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;

                a {
                    margin: 10px 10px 0 10px;
                }
    
                img {
                    max-height: 36px;
                }
            }
    
        p {
            position: absolute;
            text-align: left;
            bottom: 5vh;
            left: 5vh;
            color: #D6D6D6;
            font-size: 12px;
        }
    }
    
    #leaves {
        position: absolute;
        top: -4vh;
        left: -4vw;
        z-index: -2;
        max-width: 74vw;
        max-height: 64vh;
        animation: leaves 4s ease-in-out -1s infinite alternate;

        @keyframes leaves {
            from {
                transform-origin: 0% 0%;
                transform: rotate(-2deg);
              }
              to {
                transform-origin: 0% 0%;
                transform: rotate(2deg);
              }
          }
    }

    #flamingo {
        position: absolute;
        bottom: -5vh;
        right: -5vw;
        z-index: -2;
        max-width: 60vh;
        animation: flamingo 4s ease-in-out infinite alternate;

        @keyframes flamingo {
            from {
                transform-origin: 50% 100%;
                transform: rotate(0deg) scaleX(-1);
              }
              to {
                transform-origin: 50% 100%;
                transform: rotate(4deg) scaleX(-1);
              }
          }
    }
}

@media only screen and (max-width: 1440px) {
    .splashWrapper{
        #flamingo{
            display: none;
        }
        
    }
  }

  @media only screen and (max-width: 960px) {
    .splashWrapper{
        .container{
            #logo {
                width: 100%;
            }

            a, h1{
                font-size: 16px;
            }
        }
    }
  }